<template>
  <div class="full-width">
    <document-toolbar
      :delete-document="deleteProcess"
      :show-cancel="!readonly"
      :show-delete="!readonly"
      :show-save="!readonly"
      :revert="refreshDocument"
      :save="saveDocument"
      :specialActions="specialActions"
      :tab="tab"
      v-on="$listeners"
      no-history
    >
    </document-toolbar>
    <document-in-tab>
      <cot-form
        ref="form"
        :value="tab.canSave"
      >
        <cot-form
          ref="headerForm"
          v-model="headerForm"
          @input="validateMainForm"
        >
          <grid
            item-min-width="300px"
            class="align-center "
          >
            <translations
              :is-new="documentIsNew"
              :label="$t('t.Name')"
              :rules="nameRules"
              :translations.sync="computedName"
              class="mr-4"
            />
            <translations
              :is-new="documentIsNew"
              :label="$t('t.Description')"
              :translations.sync="computedDescription"
            />
            <div>
              <v-checkbox
                :label="$t('t.Active')"
                class="ma-0"
                hide-details
                v-model="computedIsActive"
              />
              <v-checkbox
                :label="$t('t.RunAfterImport')"
                :disabled="!computedIsActive"
                class="ma-0"
                hide-details
                v-model="computedRunAfterImport"
              />
            </div>
          </grid>
          <grid
            item-min-width="300px"
            class="align-center mt-2"
          >
            <v-autocomplete
              :label="$t('t.Documents')"
              :items="documentTypes"
              item-text="text"
              item-value="id"
              :rules="required"
              :readonly="!canChangeDocumentType"
              v-model="computedViewType"
            />
            <v-autocomplete
              v-if="computedViewType"
              ref="actionTypes"
              :label="$t('t.ProcessActions')"
              :items="computedActionItems"
              :rules="required"
              item-text="text"
              item-value="id"
              v-model="computedActionType"
            />
            <div class="d-flex gap">
              <filter-params
                v-if="computedViewType"
                v-model="computedFilter"
                :auto-filter-type="computedViewType"
                consolidable
              />
              <filter-params
                class="ml-4"
                v-if="computedViewType"
                v-model="computedFilterPrevious"
                :auto-filter-type="computedViewType"
                :label="$t('t.FilterPrevious')"
                consolidable
              />
            </div>
          </grid>
        </cot-form>
        <levels
          class="mt-2"
          v-if="computedViewType && computedProcessActions"
          :document.sync="computedProcessActions"
          @duplicate-level="onDuplicateAction"
          @remove-level="onRemoveAction($event)"
          :default-level="defaultAction"
          :label="$t('t.ProcessActions')"
          :custom-actions="computedActions"
        >
          <template v-slot:level-header="{index}">
            <v-icon
              v-if="computedProcessActions[index] && !subForms[index]"
              class="mr-2"
              color="error"
            >
              {{$icon('i.Error')}}
            </v-icon>
            <span>{{displayActionName(index)}}</span>
          </template>
          <template v-slot:level-content="{index}">
            <template v-if="computedProcessActions[index]">
              <cot-form
                v-model="subForms[index]"
                @input="validateMainForm"
              >
                <action
                  class="mt-2"
                  :document.sync="computedProcessActions[index]"
                  :view-type="computedViewType"
                  :action-type.sync="computedActionType"
                  :dense="false"
                />
              </cot-form>
            </template>
          </template>
        </levels>
      </cot-form>
    </document-in-tab>
    <v-dialog
      v-model="showRunPopUp"
      max-width=500
      @click:outside="showRunPopUp = false"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text>
          <span class="mr-1">{{runProcessConfirmation}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="doRun()"
            :loading="executionInProgress"
          >{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="showRunPopUp = false"
          >{{$t(!executionInProgress ? 't.No' : 't.ClosePopUp')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showResetPopUp"
      max-width=500
      @click:outside="showResetPopUp = false"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text>
          <span class="mr-1">{{resetProcessConfirmation}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="doReset()">{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="showResetPopUp = false"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DocEdit from '@/mixins/document-editor'

export default {
  mixins: [DocEdit],
  components: {
    Action: () => import('./action.vue'),
    CotForm: () => import('@/components/cot-form'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    FilterParams: () => import('@/pages/cameleon/columns/paramsType/filter-params/filter-params'),
    Grid: () => import('@/components/grid'),
    Levels: () => import('@/components/levels'),
    Translations: () => import('@/components/translations')
  },
  computed: {
    cacheType () {
      return DocEdit.CacheType.ProcessDetail
    },
    canChangeDocumentType () {
      return !this.computedProcessActions?.length || !this.computedProcessActions[0]?.params?.c?.type
    },
    computedActionItems () {
      return this.dataActionItems
    },
    computedFilter: {
      get () { return this.document?.filter },
      set (v) {
        this.document.filter = this.lodash.cloneDeep(v)
      }
    },
    computedFilterPrevious: {
      get () { return this.document?.filterPrevious },
      set (v) {
        this.document.filterPrevious = this.lodash.cloneDeep(v)
      }
    },
    computedViewType: {
      get () { return this.document?.viewType },
      set (v) {
        this.document.viewType = v
      }
    },
    computedActionType: {
      get () { return this.dataActionType },
      set (v) {
        this.dataActionType = v
      }
    },
    computedName: {
      get () { return this.document?.name || {} },
      set (v) {
        this.document.name = this.lodash.cloneDeep(v)
      }
    },
    computedDescription: {
      get () { return this.document?.description || {} },
      set (v) {
        this.document.description = v
      }
    },
    computedIsActive: {
      get () { return this.document?.isActive },
      set (v) {
        this.document.isActive = v
        if (!v) {
          this.document.runAfterImport = v
        }
      }
    },
    computedRunAfterImport: {
      get () { return this.document?.runAfterImport },
      set (v) {
        this.document.runAfterImport = v
      }
    },
    computedProcessActions: {
      get () { return this.document?.processActions },
      set (v) {
        this.document.processActions = this.lodash.cloneDeep(v)
      }
    },
    computedActions () {
      const actions = [
        {
          text: this.$t('t.RunProcess'),
          icon: this.$icon('i.Processes'),
          action: index => this.runProcessAction(index),
          isVisible: () => this.tab.isPristine || !this.documentIsNew
        }]

      if (this.canReset) {
        actions.push({
          text: this.$t('t.ResetProcess'),
          icon: this.$icon('i.Refresh'),
          action: index => this.resetProcessAtion(index),
          isVisible: () => this.tab.isPristine || !this.documentIsNew
        })
      }

      return actions
    },
    canReset () {
      return this.dataActionType !== 'assign-to-work-queue' && this.dataActionType !== 'update-topic-status'
    },
    documentTypes () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.lodash.sortBy([
        { id: 'accounts', text: this.$t('t.Accounts') },
        { id: 'account-contacts', text: this.$t('t.AccountContacts') },
        { id: 'collaboration-tasks', text: this.$t('t.CollaborationTasks') },
        { id: 'disputes', text: this.$t('t.Disputes') },
        { id: 'invoices', text: this.$t('t.Invoices') },
        { id: 'messages-inbox', text: this.$t('t.MessagesInbox') },
        { id: 'messages-outbox', text: this.$t('t.MessagesOutbox') },
        { id: 'promises', text: this.$t('t.Promises') },
        { id: 'topics', text: this.$t('t.Topics') },
        { id: 'users', text: this.$t('t.Users') },
        { id: 'summaries', text: this.$t('t.Summaries') },
        { id: 'work-item-templates', text: this.$t('t.WorkItemTemplates') }
      ], 'text')
    },
    resetProcessConfirmation () {
      const name = this.indexAction != null ? this.computedProcessActions[this.indexAction].name[this.$store.getters.currentUser.culture] : this.computedName[this.$store.getters.currentUser.culture]
      const translate = this.indexAction != null ? 't.ResetProcessActionConfirmation' : 't.ResetProcessConfirmation'
      return this.$t(translate, { title: this.truncateWithEllipsis(name) })
    },
    runProcessConfirmation () {
      const name = this.indexAction != null ? this.computedProcessActions[this.indexAction].name[this.$store.getters.currentUser.culture] : this.computedName[this.$store.getters.currentUser.culture]
      return this.$t('t.RunProcessConfirmation', { title: this.truncateWithEllipsis(name) })
    },
    nameRules () {
      const MIN = 1
      const MAX = 100
      return [
        v => !!v.length || this.$t('t.IsRequired'),
        v => (v.length >= MIN && v.length <= MAX) || this.$t('t.Length')
      ]
    },
    id () {
      return this.tab.documentId
    },
    specialActions () {
      if (this.documentIsNew || !this.tab.isPristine) { return undefined }

      const actions = []
      actions.push({
        text: this.$t('t.RunProcess'),
        icon: this.$icon('i.Processes'),
        callback: this.openRunPopUp
      })

      if (this.canReset) {
        actions.push({
          text: this.$t('t.ResetProcess'),
          icon: this.$icon('i.Refresh'),
          callback: this.openResetPopUp
        })
      }

      if (this.id) {
        actions.push({
          text: this.$t('t.Duplicate'),
          icon: this.$icon('i.Templates'),
          callback: this.duplicate
        })
      }
      return actions
    },
    readonly () {
      return !this.$store.getters.currentUserHasPermission('TaskEngines')
    }
  },
  data () {
    return {
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      headerForm: false,
      dataActionType: null,
      dataActionItems: [],
      executionInProgress: false,
      required: [v => (!!v && !this.lodash.isEqual(v, {}) && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      showRunPopUp: false,
      showResetPopUp: false,
      indexAction: null,
      subForms: []
    }
  },
  methods: {
    cancel () {
      this.refreshDocument()
    },
    duplicate () {
      this.cache.doc.id = null
      this.document.id = null
      this.document.name = undefined

      this.document.processActions = this.lodash.cloneDeep(this.document?.processActions ?? []).map(a => {
        a.id = null
        return a
      })
    },
    openRunPopUp () {
      this.indexAction = null
      this.showRunPopUp = true
    },
    openResetPopUp () {
      this.indexAction = null
      this.showResetPopUp = true
    },
    defaultAction () {
      return {
        filter: { consolidated: true },
        filterPrevious: { consolidated: true },
        name: null,
        params: { type: this.computedViewType, c: { type: this.computedActionType } }
      }
    },
    onDuplicateAction (newAction) {
      newAction.id = null
    },
    onRemoveAction (index) {
      this.subForms.splice(index, 1)
      this.validateMainForm()
    },
    async deleteProcess () {
      const apiUrl = '/core/v6/process'
      this.$http().delete(`${apiUrl}/${this.id}`).then(async () => {
        this.$router.push('/').catch(e => console.log(e))
      }).catch(e => {
        this.$store.dispatch(
          'showErrorSnackbar',
          e.response?.data?.message
        )
      })
    },
    async doReset () {
      const apiUrl = `/core/v6/process/${this.id}/reset`

      try {
        await this.$http().post(apiUrl, { processActionId: this.computedProcessActions[this.indexAction]?.id })
      } catch (e) {
        this.$store.dispatch('showErrorSnackbar')
        return
      }

      this.showResetPopUp = false

      this.$store.dispatch('showSuccessSnackbar', this.$t('t.Success'))
    },
    async doRun () {
      this.executionInProgress = true
      const apiUrl = `/core/v6/process/${this.id}/run`

      try {
        await this.$http().post(apiUrl, { processActionId: this.computedProcessActions[this.indexAction]?.id })
      } catch (e) {
        this.$store.dispatch('showErrorSnackbar')
        return
      } finally {
        this.executionInProgress = false
      }

      this.showRunPopUp = false

      this.$store.dispatch('showSuccessSnackbar', this.$t('t.Success'))
    },
    runProcessAction (index) {
      this.indexAction = index
      this.showRunPopUp = true
    },
    resetProcessAtion (index) {
      this.indexAction = index
      this.showResetPopUp = true
    },
    displayActionName (index) {
      const defaultName = this.$t('t.ProcessAction') + ' ' + (index + 1)
      return this.document?.processActions?.[index]?.name?.[this.$store.getters.currentUser.culture] ||
        defaultName
    },
    emitDocument () {
      const doc = this.document

      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        this.document = Object.assign(
          {},
          this.document,
          {
            name: {},
            description: {},
            processActions: [],
            filter: { consolidated: true },
            filterPrevious: { consolidated: true },
            isActive: false,
            runAfterImport: false,
            viewType: this.document?.viewType
          }
        )
        this.dataDocumentEmit()
      }
    },
    async save () {
      const apiUrl = '/core/v6/process'

      if (this.documentIsNew) {
        const response = await this.$http().post(apiUrl, this.document)
        this.$nextTick(() => {
          this.$router.push('/processes/' + response.data?.id)
        })
        return response
      } else {
        return await this.$http().put(`${apiUrl}/${this.id}`, this.document)
      }
    },
    validateMainForm () {
      this.$nextTick(() => this.$emit('document-can-save', { tab: this.tab, value: Boolean(this.subForms.every(f => f) && this.$refs?.headerForm?.value) }))
    },
    async getActionItems (v) {
      return (await this.$http().get('/core/v6/cameleon/action-items/' + v))?.data?.actions ?? []
    }
  },
  props: {
    tab: Object
  },
  watch: {
    computedViewType: {
      immediate: true,
      async handler (v) {
        if (v) {
          this.dataActionItems = await this.getActionItems(v)
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.full-width
  width 100%
</style>
